import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
import ViewPdf from '../Utils/viewPdf';

const Forms = () => {
    return (
        <>
            <SEO title="Forms" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Forms</h3>
                <Pageheader />
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">
                        <h4 className="title font-weight-bold text-default">Reserve Facility</h4>
                        <ViewPdf title="Reserve Facility" pdf='https://iowaht.s3.us-east-2.amazonaws.com/pdf/forms/Temple_Services_Facilities_Reservation_Form.pdf' />

                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">
                        <h4 className="title font-weight-bold text-default">Nitya Pooja Membership</h4>
                        <ViewPdf title="Nitya" pdf='https://iowaht.s3.us-east-2.amazonaws.com/pdf/forms/Nityapooja.pdf' />

                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">
                        <h4 className="title font-weight-bold text-default">New Temple Donation</h4>
                        <ViewPdf title="Reserve Facility" pdf='https://iowaht.s3-us-east-2.amazonaws.com/pdf/forms/Building_Construction_Donation_Form_2015_08_15.pdf' />

                    </MDBCol>
                </MDBRow>
            </MDBContainer>

        </>
    );
};

export default Forms;